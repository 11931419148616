import React from "react";
import { Icons } from "../Uikit/Icon/Icon";

export const FILE_INPUT_ACCEPTED_TYPES: { [key: string]: string } = {
    // file type: extension
    "application/vnd.ms-excel": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow": "ppsx",
    // "video/x-msvideo": "avi",
    // "video/avi": "avi",
    "video/mp4": "mp4",
    // "video/x-ms-wmv": "wmv",
    // "image/mov": "mov",
    // "video/quicktime": "mov",
    // "video/x-quicktime": "mov",
    "audio/mpeg": "mp3",
    "audio/wav": "wav",
    "image/jpeg": "jpeg",
    "image/png": "png",
    "image/gif": "gif",
    "application/pdf": "pdf",
    "text/plain": "txt",
};

export const FILES_TYPE_ICONS: { [key: string]: React.ReactElement } = {
    zip: Icons.FileZip,
    xml: Icons.FileXml,
    xls: Icons.FileXls,
    xlsx: Icons.FileXls,
    txt: Icons.FileTxt,
    rar: Icons.FileRar,
    ppt: Icons.FilePpt,
    pps: Icons.FilePpt,
    png: Icons.FilePng,
    pdf: Icons.FilePdf,
    mp3: Icons.FileMp3,
    json: Icons.FileJson,
    jpeg: Icons.FileJpg,
    jpg: Icons.FileJpg,
    html: Icons.FileHtml,
    exe: Icons.FileExe,
    doc: Icons.FileDoc,
    docx: Icons.FileDoc,
    csv: Icons.FileCsv,
    bmp: Icons.FileBmp,
    wmv: Icons.FileWmv,
    wav: Icons.FileWav,
    tiff: Icons.FileTiff,
    psd: Icons.FilePsd,
    mpg: Icons.FileMpg,
    mp4: Icons.FileMp4,
    mov: Icons.FileMov,
    gif: Icons.FileGif,
    eps: Icons.FileEps,
    avi: Icons.FileAvi,
    ods: Icons.FileOds,
    ppsx: Icons.FilePptx,
    pptx: Icons.FilePptx,
    odt: Icons.FileOdt,
    odf: Icons.FileOdf,
};

export const FILES_TYPE_SHORT_NAME: { [key: string]: string } = {
    zip: "ZIP",
    xml: "XML",
    xls: "XLS",
    xlsx: "XLSX",
    txt: "TXT",
    rar: "RAR",
    ppt: "PPT",
    png: "PNG",
    pdf: "PDF",
    mp3: "MP3",
    json: "JSON",
    jpg: "JPG",
    jpeg: "JPG",
    html: "HTML",
    exe: "EXE",
    doc: "DOC",
    docx: "DOCX",
    csv: "CSV",
    bmp: "BMP",
    wmv: "WMV",
    wav: "WAV",
    tiff: "TIFF",
    psd: "PSD",
    mpg: "MPG",
    mp4: "MP4",
    mov: "MOV",
    gif: "GIF",
    eps: "EPS",
    avi: "AVI",
    ods: "ODS",
    ppsx: "PPSX",
    pptx: "PPTX",
    odt: "ODT",
};
