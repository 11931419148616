export enum UIErrorMessages {
    FILE_LOADING_ERROR = "Ошибка при загрузке, загрузите другой файл",
    FILE_EXTENSION_ERROR = "Ошибка при загрузке, выбран недопустимый формат файла",
    FILE_SIZE_ERROR = "Превышен максимальный размер файла",
}

export enum WSEventVideoActionTypes {
    AWAITING_STATUS = "AWAITING_STATUS",
    PROCESSING_IN_PROGRESS = "PROCESSING_IN_PROGRESS",
    PROCESSING_COMPLETE = "PROCESSING_COMPLETE",
}