import React, { useCallback, useState } from 'react';
import { FileUpload } from '../Uikit/FileUpload';
import { Button } from '../Uikit/Button';
import { Label } from '../Uikit/forms/Label';
import { Icon, Icons } from '../Uikit/Icon/Icon';
import { FILES_TYPE_ICONS } from '../constants/attachments';
import { humanFileSize } from '../helpers/humanFileSize';
import { flash } from '../Uikit/Notification/flash';
import { Toggle } from '../Uikit/Toggle';
import { TranscriptionWrapper } from '../components/TranscriptionWrapper';

const initCategories: string = 
`{Клиента не слышно}: Оператор в звонке не слышал клиента и завершил разговор (не путать с обрывом связи)
{Нецелевой звонок, трансфер в ВКЦ}: Когда клиент работает не официально, то направляем во внутренний контакт-центр, но если он на пенсии, то эту категорию не ставим
{Обрыв связи}: Подразумевается любое внезапное окончание диалога, где не было явного отказа или согласия от клиента.
{Отказ: Получил отказ в офисе Банка}: Ситуации, когда мы говорим с клиентом, он говорит, что хочет оформить продукт, а потом на предложение оформить в процессе звонка говорит, что вообще-то ему отказал Банк
{Отказ: При заполнении заявки получили отказ}: Только по дебетовой карте, оформляли во время звонка с клиентом и ему отказали
{Отказ: другое}: при оформлении заявки в звонке случилась какая-то ошибка и на этом разговор закончился, если после ошибки разговор продолжается и оператором предлагаются иные действия (ссылка, навигация в банк и прочее), то эту категорию не ставим
{Отказ: клиент не соответствует требованиям}: Если клиент инвалид или не работает, Клиент не подходит по базовым требованиям (например, статус занятости).
{Отказ: надо посоветоваться}: Клиент спрашивал условия, потом сказал, что нужно посоветоваться
{Отказ: не назвал причину}: Применяется только если нет конкретного отказа и нет точной причины. если клиент проявил интерес к условиям, но завершил разговор без дальнейших действий (оформления или запроса на продолжение), то такая ситуация трактуется как "не назвал причину
{Отказ: не устроили условия по продукту}: используется, если клиент выражает несогласие или неудовольствие условиями продукта и явно или неявно отказывается от него, даже если он говорит, что "подумает" 
{Отказ: неудобное расположение банка}: Оформить в звонке заявку не получилось по какой-либо причине, а в месте проживания клиента нет офисов, эта категория применяется, если клиент прямо или косвенно выражает сомнение или неудобство, связанное с поездкой в офис, и в итоге не соглашается на оформление из-за этого фактора, Применяется, если клиент выражает сомнение в поездке в офис и в результате отказывается от оформления, либо если клиент говорит, что не сможет посетить офис из-за неудобного местоположения
{Согласие: заведение заявки на звонке}: Ставим в разговорах с продажей продукта, даже когда в процессе оформления приходит Отказ банка, но не когда звонок неожиданно закончился или оборвался, если диалог прервался без завершения процесса оформления заявки, это означает, что достигнутый результат не соответствует данной категории
{Согласие: навигация в представительство банка}: Ставим, когда при оформлении в звонке выходит какая-то ошибка или клиент не хочет называть коды в разговоре. Подбираем офис, отправляем смс с адресом и графиком работы, спрашиваем, когда пойдет. Используйте эту категорию, когда оператор предоставляет клиенту адрес и режим работы офиса банка с целью посещения, часто с отправкой СМС, а клиент выражает согласие (даже молчаливое) принять информацию. Ставится, если диалог завершается намерением клиента посетить офис или при получении подробностей о расположении офиса. Не используйте, если разговор только с информационными вопросами без намерения навигации в офис
{Согласие: ссылка отправлена}: Не хочет в звонке называть свои данные или не приходят коды и т.п. После отправки смс с ссылкой спрашиваем, открывается ли она, и предлагаем заполнить вместе. Если клиент говорит, что заполнит сам — даем рекомендации. Если соглашается, то мы с ним "идем", он сам заполняет, мы подсказываем, и ставим "Согласие: заведение в звонке", ссылка должна быть явно отправлена, а клиент должен выразить намерение заполнить ее самостоятельно или с помощью оператора
{Консультация}: Если клиент отказывается по итогу диалога, уходит, эту категорию не ставим, используется только в случае, когда клиент проявляет интерес и задает вопросы, но не принимает ни решения о согласии, ни отказа в конце диалога. Если клиент отказывается в любом виде, эта категория не подходит.эта категория НЕ применяется, если клиент выражает намерение отказаться от продукта, даже если он просто "подумывает"`;

interface ResultDataCategory {
    name: string;
    estimation: number;
}

interface ResultDataResponse {
    transcription: string;
    categories: ResultDataCategory[];
}

export const NotesClassification = () => {
    const [file, setFile] = useState<File | null>(null);

    const [isProcessing, setIsProcessing] = useState(false);
    const [isPre, setIsPre] =useState(true);

    const [categories, setCategories] = useState<string>(initCategories);

    const [resultData, setResultData] = useState<ResultDataResponse | null>(null);

    const uploadFile = (file: File) => {
        setFile(file);
    };
    
    const handleChangeCategories = (e: any) => {
        setCategories(e.target.value);
    };

    const handleSubmit = async () => {
        if (!file) {
            return;
        }

        setIsProcessing(true);

        const formData = new FormData();

        formData.append("file", file);
        formData.append("categories", categories);

        try {
            let response = await fetch("/api/categorize", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`${response.status} ${response.statusText}`)
            }

            let result = await response.json();

            if (!result.success) {
                flash.error("Ошибка при обработке файла, загрузите другой файл");
                return;
            }

            setResultData(result.data);
        } catch (e: any) {
            flash.error("Что-то пошло не так");
        } finally {
            setIsProcessing(false);
        }
    };

    const handleReset = () => {
        setFile(null);
        setResultData(null);
        setCategories(initCategories);
    };

    const removeFile = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        setFile(null);
    };

    const getButtonTitle = () => {
        if (resultData) {
            return "Начать заново"
        } else if (isProcessing) {
            return "Идет обработка..."
        } else {
            return "Отправить";
        }
    };

    const getFileExtension = () => {
        if (file) {
            const extensionIdx = file.name.lastIndexOf(".");
            const extension = file.name.slice(extensionIdx + 1);
            return extension.toLowerCase();
        }
        return "";
    };

    const togglePre = useCallback((val:boolean)=>{
        setIsPre(val);
    },[setIsPre]);

    return (
        <div className="max-w-[1220px] mx-auto px-7.5 py-7.5">
            <div className="flex justify-between mb-6">
                <h1>Классификация записей</h1>
                <Button
                    onClick={resultData ? handleReset : handleSubmit}
                    disabled={!file || isProcessing || !categories.trim()}
                >
                    {getButtonTitle()}
                </Button>
            </div>
            <div className="flex gap-5 items-start">
                <div className="flex-initial basis-[50%]">
                    <Label isRequired={true}>Файл</Label>
                    {!file && (
                        <FileUpload
                            onChange={uploadFile}
                            accept={{
                                "audio/mpeg": [],
                                "audio/wav": [],
                            }}
                            acceptDescription=".mp3, .wav"
                        />
                    )}
                    {file && (
                        <div className="w-full flex p-4 border border-gray-blue rounded-md cursor-pointer">
                            <div className="flex items-center mr-auto">
                                <Icon
                                    className="mr-5"
                                    icon={FILES_TYPE_ICONS[getFileExtension()]}
                                    width="32px"
                                    height="32px"
                                />
                                <div>
                                    <div className="pb-2 max-w-[550px]">
                                        <span className="block truncate w-full">{file.name}</span>
                                    </div>
                                    <div className="text-gray-text">{humanFileSize(file.size, true)}</div>
                                </div>
                            </div>
                            {!isProcessing && !resultData && (
                                <div>
                                    <div onClick={(e) => removeFile(e)} className=" px-1">
                                        <Icon
                                            icon={Icons.Delete}
                                            width={20}
                                            height={20}
                                            color="fill-blue-drk cursor-pointer"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="flex-initial basis-[50%]">
                    <Label>Список категорий</Label>
                    <textarea 
                        className="h-100 w-full resize-none focus:outline-none p-5 border border-blue-gray rounded-lg overflow-auto" 
                        value={categories}
                        onChange={handleChangeCategories}
                        disabled={isProcessing}
                    />
                </div>
            </div>
            { resultData && (
                <>
                    <div className="mb-5">
                        <Label>Категории диалога на записи</Label>
                        <div className="h-37.5 px-5 py-4 border border-blue-gray rounded-lg overflow-auto">
                            {resultData.categories.map(category => (
                                <p key={category.name}>
                                    {`${category.name} - ${category.estimation}%`}
                                </p>
                            ))}
                        </div>
                    </div>
                    <Toggle label={'Форматирование'} enabled={isPre} className='mb-4' onChange={togglePre}/>
                    <div  >
                        <Label>Текст диалога</Label>
                        <TranscriptionWrapper isPre={isPre} className="h-150 px-5 py-4 border border-blue-gray rounded-lg overflow-auto">
                            {resultData.transcription}
                        </TranscriptionWrapper>
                    </div>
                </>
            )}
        </div>
    );
};
