import React from "react"

export interface TranscriptionWrapperProps {
    isPre: boolean;
    className: string;
    children: React.ReactNode | string;
}

export const TranscriptionWrapper = (props: TranscriptionWrapperProps)=>{
    const {children, isPre, className} = props;
    const content =  isPre 
    ? <pre className={className}>{children}</pre>
    : <div className={className}>{children}</div>

    return content; 
}