import React from 'react';
import { NotesClassification } from './pages/NotesClassification';
import { CustomToastContainer } from './Uikit/CustomToastContainer/CustomToastContainer';

const App = () => {
    
    return (
        <div className="h-screen">
			<NotesClassification />
            <CustomToastContainer autoClose={5000} hideProgressBar={true} />
        </div>
    );
};

export default App;
